.spinner {
  clear: both;
  position: relative;
  width: 106px;
  height: 64px;
  div {
    display: inline-block;
    position: absolute;
    left: 6px;
    width: 13px;
    background: #fff;
    animation: spinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

    &:nth-child(1) {
      left: 6px;
      animation-delay: -0.48s;
    }

    &:nth-child(2) {
      left: 26px;
      animation-delay: -0.36s;
    }

    &:nth-child(3) {
      left: 45px;
      animation-delay: -0.24s;
    }

    &:nth-child(4) {
      left: 65px;
      animation-delay: -0.12s;
    }

    &:nth-child(5) {
      left: 85px;
      animation-delay: 0s;
    }
  }
}

@keyframes spinner {
  0% {
    top: 6px;
    height: 51px;
  }
  50%,
  100% {
    top: 19px;
    height: 26px;
  }
}
