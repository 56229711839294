body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.Resizer.disabled {
  cursor: default;
}

.scrollable-table::-webkit-scrollbar {
  width: 12px;
}

.scrollable-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scrollable-table::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.ReactVirtualized__Grid,
.ReactVirtualized__List {
  outline: none;
}

.ReactVirtualized__Table__headerColumn {
  margin-right: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ReactVirtualized__Table__rowColumn {
  display: flex;
  border-right: 1px solid #b7c0c5;
  margin-right: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 0px;
}

body .react-datetimerange-picker__wrapper {
  border: 0;
}

.layout header.MuiPaper-elevation4 {
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
}

.my-translated-paragraph {
  white-space: pre-wrap;
}
