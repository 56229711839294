:root {
  --right: 0;
  --left-box-shadow: block;
  --right-box-shadow: block;
}

$table-row-shadow: 68px 0px 7px -71px rgba(0, 0, 0, 0.75) inset;

@keyframes overlay-in {
  from {
    right: -999px;
  }

  to {
    right: var(--right);
  }
}

@keyframes overlay-out {
  from {
    right: 0;
  }

  to {
    right: -999px;
  }
}

@mixin sticky($property: 'left') {
  position: sticky;
  #{$property}: 0;
}

%drop-shadow {
  height: 102%;
  content: '';
  width: 4px;
  top: 0px;
  position: absolute;
  box-shadow: $table-row-shadow;
}

.left-sticky-none {
  @include sticky('left');
  &:after {
    content: '';
    @extend %drop-shadow;
    right: 4px;
    position: absolute;
    display: none;
  }
}

.left-sticky-block {
  @include sticky('left');
  &:after {
    content: '';
    @extend %drop-shadow;
    right: 4px;
    position: absolute;
    display: block;
  }
}

.right-sticky-none {
  @include sticky('right');
  &:before {
    content: '';
    @extend %drop-shadow;
    left: -4px;
    transform: rotate(180deg);
    display: none;
  }
}

.right-sticky-block {
  @include sticky('right');
  &:before {
    content: '';
    @extend %drop-shadow;
    left: -4px;
    transform: rotate(180deg);
    display: block;
  }
}

.word-break {
  word-break: break-word;
  hyphens: auto;
}

.row {
  position: relative;
  overflow: hidden;
  .actions {
    display: none;
    position: absolute;
    top: 50%;
    transform: translate(0, -55%);
    background-color: white !important;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    right: var(--right);
    padding: 2px;
    button {
      padding: 6px;
      margin: 0 1px;
    }
  }
  &:hover {
    .actions {
      display: block;
    }
  }
}
